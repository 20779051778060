import './App.css';
import { getUserConnection } from '@/widgets/widgets';
import { Footer } from '@/components/layout/Footer';
import { Header } from '@/components/layout/Header';
import { IntlProvider } from '@/context/IntlContext';
import { FormattedMessage } from 'react-intl';

export function App() {
  return (
    <IntlProvider>
      <div className="app">
        <Header />

        <main className="p-4">
          <i className="icon me-3">person</i>
          <FormattedMessage id={'App.Welcome'} values={{ user: getUserConnection()?.mail }} />
        </main>

        <Footer />
      </div>
    </IntlProvider>
  );
}
