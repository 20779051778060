import { ChangeEvent, useCallback } from 'react';

interface SwitcherProps {
  label: string;
  id: string;
  onChange?: (value: boolean) => void;
  checked?: boolean;
}

export function Switcher(props: SwitcherProps): JSX.Element {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.onChange?.(event.currentTarget.checked);
    },
    [props.onChange],
  );

  return (
    <div className="form-check form-switch d-flex align-items-center">
      <input
        onChange={handleChange}
        checked={props.checked}
        type="checkbox"
        className="form-check-input"
        id={props.id}
      />
      <label className="form-check-label" htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  );
}
