import { AccountCenter } from '@/components/sgwt/AccountCenter';
import { useCallback, useState } from 'react';
import { getTheme, setTheme, Theme } from '@/utils/theme';
import { Switcher } from '@/components/bootstrap/Switcher';

export function Header(): JSX.Element {
  return (
    <header className="navbar border-bottom">
      <div className="navbar-title">
        <a className="navbar-title-link" href="/">
          <div className="navbar-logo">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="0" width="100%" height="50%" fill="var(--bs-socgen)"></rect>
              <rect x="0" y="50%" width="100%" height="50%" fill="#1D1D1B"></rect>
              <rect x="17%" y="46.5%" width="65%" height="7%" fill="white"></rect>
            </svg>
          </div>
          <div className="navbar-title-divider" />
          <div className="navbar-service-name">
            React
            <br />
            Starter
          </div>
        </a>
      </div>

      <div className="navbar-content">
        <ul className="navbar-navigation m-0">
          <li className="navbar-item">
            <a className="navbar-link active" aria-current="page" href="#">
              Home
            </a>
          </li>
          <li className="navbar-item">
            <a className="navbar-link" href="#">
              Link
            </a>
          </li>
        </ul>
      </div>

      <div className="navbar-toolbar">
        <ThemeSwitcher />
        <AccountCenter />
      </div>
    </header>
  );
}

function ThemeSwitcher(): JSX.Element {
  const [currentTheme, setCurrentTheme] = useState(getTheme);
  const changeTheme = useCallback((checked: boolean) => {
    const theme: Theme = checked ? 'DARK' : 'LIGHT';
    setTheme(theme);
    setCurrentTheme(theme);
  }, []);
  return (
    <Switcher id="dark" label="Dark" checked={currentTheme === 'DARK'} onChange={changeTheme} />
  );
}
